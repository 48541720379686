import React from "react"

export default function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <div className="privacy-policy-header">Privacy Policy</div>
      <div className="privacy-policy-update">Last updated [May 4, 2020]</div>
      <div className="privacy-policy-paragraph">
        I, Danny Li, respects the privacy of our users (“user” or “you”). This
        Privacy Policy explains how I collect, use, disclose, and safeguard your
        information when you visit our mobile application (Bar is Loaded).
        Please read this Privacy Policy carefully. IF YOU DO NOT AGREE WITH THE
        TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION. I
        reserve the right to make changes to this Privacy Policy at any time and
        for any reason. I will alert you about any changes by updating the “Last
        updated” date of this Privacy Policy. You are encouraged to periodically
        review this Privacy Policy to stay informed of updates. You will be
        deemed to have been made aware of, will be subject to, and will be
        deemed to have accepted the changes in any revised Privacy Policy by
        your continued use of the Application after the date such revised
        Privacy Policy is posted. This Privacy Policy does not apply to the
        third-party online/mobile store from which you install the Application
        or make payments, including any in-game virtual items, which may also
        collect and use data about you. We are not responsible for any of the
        data collected by any such third party. This Privacy Policy was created
        using Termly’s Privacy Policy Generator.
      </div>
      <div className="privacy-policy-subheader">
        COLLECTION OF YOUR INFORMATION
      </div>
      <div className="privacy-policy-paragraph">
        I may collect information about you in a variety of ways. The
        information I may collect via the Application depends on the content and
        materials you use, and includes:
      </div>
      <div className="privacy-policy-subheader">PERSONAL DATA</div>
      <div className="privacy-policy-paragraph">
        Rest easy, I do not collect data, period. I physically can’t, there is
        no where to store it. I do not even have a database to store it. All
        saved changes are stored locally in your device and deleted if you, the
        user, choose to delete the app. There is no account sign up nor do we
        ask for any personal information. The only data that is stored from the
        application is whether you want the inputs to be in KG or LB.
      </div>
      <div className="privacy-policy-subheader">MOBILE DEVICE ACCESS</div>
      <div className="privacy-policy-paragraph">
        I may request access or permission to certain features from your mobile
        device, including your mobile device’s [data for third party links to
        websites]. If you wish to change my access or permissions, you may do so
        in your device’s settings.
      </div>
      <div className="privacy-policy-subheader">THIRD-PARTY WEBSITES</div>
      <div className="privacy-policy-paragraph">
        Bar is Loaded may contain links to third-party websites and applications
        of interest, including advertisements and external services, that are
        not affiliated with us. Once you have used these links to leave the
        Application, any information you provide to these third parties is not
        covered by this Privacy Policy, and I cannot guarantee the safety and
        privacy of your information. Before visiting and providing any
        information to any third-party websites, you should inform yourself of
        the privacy policies and practices (if any) of the third party
        responsible for that website, and should take those steps necessary to,
        in your discretion, protect the privacy of your information. I am not
        responsible for the content or privacy and security practices and
        policies of any third parties, including other sites, services or
        applications that may be linked to or from the Application.
      </div>
      <div className="privacy-policy-subheader">
        SECURITY OF YOUR INFORMATION
      </div>
      <div className="privacy-policy-paragraph">
        Although no personal information is provided, I still make sure any data
        is secure to the greatest extent possible. Do keep in mind that any
        transmission of data over the internet or any other method of electronic
        usage is not 100% secure and thus cannot be guaranteed.
      </div>
      <div className="privacy-policy-subheader">POLICY FOR CHILDREN</div>
      <div className="privacy-policy-paragraph">
        I do not knowingly solicit information from or market to children under
        the age of 13. If you become aware of any data I have collected from
        children under age 13, please contact us using the contact information
        provided below.
      </div>
      <div className="privacy-policy-subheader">CONTACT US</div>
      <div className="privacy-policy-paragraph">
        If you have questions or comments about this Privacy Policy, please
        contact me at:{" "}
        <a className="email" href="mailto:dongledan@yahoo.com">
          dongledan@yahoo.com
        </a>
        .
      </div>
    </div>
  )
}
